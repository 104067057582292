<template>
  <div
    class="container"
    style="margin-top: 48px; margin-bottom: 48px;">
    <div class="DetailsPage__Content">
      <div
        class="ContactVdBlock"
        style="margin-left: auto;margin-right: auto;">
        <div class="col-md-12">
          <h1 class="title_faq">
            Frequently asked questions
          </h1>
          <ul class="nav nav-tabs faq">
            <li class="active faq_li">
              <a
                data-toggle="tab"
                href="#rent"
                class="faq_link">Rent-a-car</a>
            </li>
          </ul>

          <div class="tab-content faq_tabs">
            <div
              id="rent"
              class="tab-pane active">
              <h3 class="headinng_sec">
                Will there be repossessed vehicles as part of this offering?
              </h3>
              <p class="text_sec">
                No, there will not be any repossessed vehicles available.
              </p>
              <h3 class="headinng_sec">
                Are there specified minimum & maximum time periods for a rental?
              </h3>
              <p class="text_sec">
                Yes, there is minimum rental of 1 year and a maximum rental of 4 years.
              </p>
              <h3 class="headinng_sec">
                How many vehicles am I allowed to rent?
              </h3>
              <p class="text_sec">
                Subject to affordability, clients are allowed only 1 rental vehicle at any time. This excludes any vehicles the client may have financed on instalment sale.
              </p>
              <h3 class="headinng_sec">
                Who do I report any vehicle faults or defects to?
              </h3>
              <p class="text_sec">
                All vehicles will be covered by the existing/remaining manufacturer’s warranty. Any defect / fault in the vehicle must be taken up directly with the manufacturer via an authorised dealer.
              </p>
              <h3 class="headinng_sec">
                Will the installed tracking unit be used to monitor driver behaviour?
              </h3>
              <p class="text_sec">
                Yes, as the vehicle is owned by the Bank it is important for the Bank to monitor the use of the vehicle. It is also a requirement from the insurer that a tracking device be installed in the vehicle.
              </p>
              <h3 class="headinng_sec">
                Is maintenance of the vehicle included in the monthly rental?
              </h3>
              <p class="text_sec">
                No, the rental only covers the vehicle usage, insurance, tracking, roadside assist and smash and grab. It does not cover the maintenance/servicing of the vehicle. It also does not cover fuel, toll fees, tyres, vehicle license renewals and traffic fines - these are for the cost of the client.
              </p>
              <h3 class="headinng_sec">
                Who is allowed to drive the vehicle?
              </h3>
              <p class="text_sec">
                A driver with a valid driver’s license and as per insurance terms & conditions.
              </p>
              <h3 class="headinng_sec">
                May I fit extras and/or accessories to the rental vehicle?
              </h3>
              <p class="text_sec">
                No extras and/or accessories may be fitted to the rental vehicle.
              </p>
              <h3 class="headinng_sec">
                Who is liable to maintain / service the vehicle?
              </h3>
              <p class="text_sec">
                The client is responsible for the regular maintenance or service of the vehicle in accordance with the manufacturer’s maintenance or service plan, where applicable.
              </p>
              <h3 class="headinng_sec">
                Will all vehicles have a maintenance or service plan?
              </h3>
              <p class="text_sec">
                Vehicles will have the remaining term of the maintenance or service plan. Where the maintenance plan or service plan on a vehicle has lapsed, the client will still be required to maintain and service the vehicle at their cost.
              </p>
              <h3 class="headinng_sec">
                Will there be a limit to the mileage for the term of the rental agreement?
              </h3>
              <p class="text_sec">
                There will be bands of mileage clients can choose from and it will be the responsibility of the driver to manage kilometres utilised.
              </p>
              <h3 class="headinng_sec">
                What if I exceed my contracted kilometres?
              </h3>
              <p class="text_sec">
                There will be an excess cents per kilometre (CPK) rate in the rental agreement that will be charged for excess usage.
              </p>
              <h3 class="headinng_sec">
                Can I terminate the rental agreement before the expiry of the term?
              </h3>
              <p class="text_sec">
                Yes, the client is able to terminate the agreement before the term expires. However they will be liable for early termination fees as set out in the terms and conditions of the rental agreement.
              </p>
              <h3 class="headinng_sec">
                How often will vehicles be available and offered to clients on a rental basis?
              </h3>
              <p class="text_sec">
                Vehicles will be available as and when vehicles are required to be disposed of from a Standard Bank Fleet Management perspective. Vehicles can be rented on a first come, first serve basis via electronic submission only and subject to affordability.
              </p>
              <h3 class="headinng_sec">
                Can I trade in my current vehicle?
              </h3>
              <p class="text_sec">
                You can trade-in your current vehicle through an independent 3rd party. The trade-in of the current vehicle will not be linked to the rental of the new vehicle and nor will Standard Bank be involved in or take any responsibility for the trade-in of your current vehicle.
              </p>
              <h3 class="headinng_sec">
                What happens if I am interested in a vehicle in another province?
              </h3>
              <p class="text_sec">
                There will be a capability to move vehicles between major metro’s (i.e. Johannesburg, Pretoria, Cape Town, Durban, Port Elizabeth, Polokwane, Nelspruit and Bloemfontein). This arrangement will be for the costs of the client.
              </p>
              <h3 class="headinng_sec">
                Can I purchase the vehicle before the end of the contract?
              </h3>
              <p class="text_sec">
                Yes, a market related purchase price will be supplied and the client can either finance the vehicle or purchase it cash.
              </p>
              <h3 class="headinng_sec">
                Can I choose to purchase the vehicle after the rental term has expired?
              </h3>
              <p class="text_sec">
                Yes, a market related purchase price will be supplied and the client can either finance the vehicle or purchase it cash.
              </p>
              <h3 class="headinng_sec">
                How many car keys and alarm/immobilizers will I receive?
              </h3>
              <p class="text_sec">
                2 sets. Both sets must be returned to the bank at the end of the rental term.
              </p>
              <h3 class="headinng_sec">
                In the event of an accident, damage, theft, etc. of the rental vehicle, is there an excess payable by me?
              </h3>
              <p class="text_sec">
                Yes, an excess payable in line with insurance requirements and as seen in the insurance terms and conditions.
              </p>
              <h3 class="headinng_sec">
                If the rental vehicle is involved in an accident, damaged, stolen, written off, etc., do I receive a replacement rental vehicle?
              </h3>
              <p class="text_sec">
                Yes, a hired vehicle is supplied on a short-term basis as part of the insurance and subject to the terms of the insurance policy and the rental agreement.
              </p>
              <h3 class="headinng_sec">
                Who do I contact for any questions/queries relating to my rental vehicle?
              </h3>
              <p class="text_sec">
                Application and contract signing: vafprivatebankinginland@standardbank.co.za
                <br>
                <br>
              </p>
              <p class="text_sec">
                Lifecycle of the rental agreement: shifting.gears@standardbank.co.za
              </p>
            </div>
          </div>
        </div>
      </div><!-- end VdBlock -->
    </div><!-- end DetailsPage__Content -->
  </div><!-- end Container -->
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name:"FAQ",
  watch: {
    $route() {
      this.selectTab();
    }
  },
  mounted() {
    this.$nextTick(() => {
      $("ul.nav-tabs li a").click(function() {
        $("ul.nav-tabs li.active").removeClass("active");
        $(this)
          .parent("li")
          .addClass("active");
      });
    });

    this.selectTab();

    if(this.$route.params.section) {
      ScrollTo.scrollTo(this.$route.params.section)
    }
  },
  methods: {
    selectTab() {
      $("ul.nav-tabs li.active").removeClass("active");
      $(".tab-pane").removeClass("active");
      $(this.$route.hash).addClass("active")
      $("a[href='"+this.$route.hash+"']")
        .parent("li")
        .addClass("active");
    }
  }
}
</script>

<style scoped>
  /* ===== Faq ===== */
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0; }

  li {
    display: table;
    counter-increment: item;
    margin-bottom: 0; }

  li:before {
    content: counters(".") ". ";
    display: table-cell;
    padding-right: 0.6em; }

  li li {
    margin: 0; }

  li li:before {
    content: counters(item, ".") " ";
    font-weight: 600; }

  .tab-content.faq_tabs > .active {
    border: 0px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 48px;
    background: none; }

  a.readmore {
    text-decoration: underline; }

  h3.headinng_sec {
    padding-top: 33px;
    font-size: 22px;
    color: #0A2240;
    font-weight: 600;
    line-height: 27px; }

  h3.headinng_sec_2 {
    padding-top: 33px;
    font-size: 22px;
    color: #0A2240;
    margin-bottom: 6px; }

  li.active.faq_li {
    padding: 12px;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #ffffff;
    background-color: #ffffff;
    margin-bottom: -1px;
    font-size: 16px; }

  li.faq_li {
    padding: 12px;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 0px;
    background-color: #F4F4F4;
    font-size: 16px; }

  .tab-content.faq_tabs > .active {
    border: 1px solid #E0E0E0;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 48px; }

  a.faq_link {
    color: #0A2240; }

  ul.nav.nav-tabs.faq {
    border-bottom: 0px; }

  p.text_sec {
    font-size: 14px;
    line-height: 17px; }

  .title_faq {
    font-size: 36px;
    font-weight: 100;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 48px;
    padding-bottom: 24px; }

  ol.terms_ol li {
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px; }

  ol.terms_ol span {
    font-size: 22px;
    font-weight: 600;
    color: #0A2240;
    padding-right: 12px; }

  @media (max-width: 426px) {
    li.active.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #ffffff;
      background-color: #ffffff;
      margin-bottom: -1px;
      font-size: 16px;
      width: 33.33%;
      text-align: center; }
    li.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 0px;
      background-color: #F4F4F4;
      font-size: 16px;
      width: 33.33%;
      text-align: center; } }

  @media (max-width: 376px) {
    ol.terms_ol li {
      font-size: 12px;
      font-weight: 300;
      color: #0A2240;
      line-height: 24px;
      margin-bottom: 18px; }
    ol.terms_ol span {
      font-size: 12px;
      font-weight: 600;
      color: #0A2240;
      padding-right: 12px; }
    h3.headinng_sec {
      padding-top: 33px;
      font-size: 14px;
      color: #0A2240;
      line-height: 17px; }
    li.active.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #ffffff;
      background-color: #ffffff;
      margin-bottom: -1px;
      font-size: 12px; }
    p.text_sec {
      font-size: 14px;
      line-height: 17px; }
    li.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 0px;
      background-color: #F4F4F4;
      font-size: 12px; } }



  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ul > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  ul > li:before {
    content: "-";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ul > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  li ul > li:before {
    content: "-";
  }

  .Table
  {
    display: table;
    width: 100%;
  }
  .Title
  {
    display: table-caption;
    font-weight: bold;
    font-size: larger;
  }
  .Heading
  {
    display: table-row;
    font-weight: bold;
  }
  .Row
  {
    display: table-row;
  }
  .Cell
  {
    display: table-cell;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
  }
  .Cell1
  {
    display: table-cell;
    width: 30%;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
  }
</style>
